import React, { useState } from 'react';
import { Slider } from 'antd';
import './DialogBox.css'; // Import your CSS file


const marks = {
  0: '0',
  20: '1',
  40: '2',
  60: '3',
  80: '4',
  100: '5',
};
function DialogBox(props) {
  const [rating, setRating] = useState(1);
  const [selectedOption, setSelectedOption] = useState('');

  const handleRatingChange = (event) => {
    setRating(event.target.value);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = () => {
    // Make your API call here with the 'rating' and 'selectedOption' values
    // After the API call is successful, you can close the dialog box
    // You can use props.onClose() to notify the parent component to close the dialog
  };

  return (
    <div className="dialog-box">
      <div className="dialog-content">
        <h2>Please select an option</h2>
        <p>How likely would you suggest this to your friends?</p>
        <Slider marks={marks} step={null} defaultValue={0} />

        <select value={rating} onChange={handleRatingChange}>
          {Array.from({ length: 10 }, (_, index) => (
            <option key={index} value={index + 1}>
              {index + 1}
            </option>
          ))}
        </select>
        <p>I will pay $10 for premium:</p>
        <input
          type="radio"
          name="paymentOption"
          value="premium"
          onChange={handleOptionChange}
        />
        <p>I want it for free:</p>
        <input
          type="radio"
          name="paymentOption"
          value="free"
          onChange={handleOptionChange}
        />
        <div className="button-container">
          <button onClick={handleSubmit}>Submit</button>
          <button onClick={props.onClose}>Close</button>
        </div>
      </div>
    </div>
  );
}

export default DialogBox;
