import React, { useState, useEffect} from 'react';
import { API_BASE_URL } from './constants';

import './FileUpload.css';
import HomePage from './HomePage';
import axios from 'axios';
import { message } from 'antd';

function FileUpload(props) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleFileSelect = (e) => {
  const files = Array.from(e.target.files);

  // Check the file size for each selected file
  const filesToUpload = [];
  const oversizedFiles = [];

  files.forEach((file) => {
    if (file.size <= 5 * 1024 * 1024) { // 5MB in bytes
      filesToUpload.push(file);
    } else {
      oversizedFiles.push(file.name);
    }
  });

  if (oversizedFiles.length > 0) {
    // Show an alert for oversized files
    alert(`Please select files smaller than 5MB: ${oversizedFiles.join(', ')}`);
  }

  setSelectedFiles(filesToUpload);
//    const files = Array.from(e.target.files);
//    setSelectedFiles(files);
// handleUpload();
  };

  useEffect(() => {
    handleUpload();
  }, [selectedFiles]);
  

  const handleToggleContainer = () => {

    props.toggleUploadContainer();

  };


  // Handle file drop
  const handleFileDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    setSelectedFiles(files);
    // handleUpload();
  };
  const handlePushDataToSheets = (sheetName, data) => {
    const feedbackData = {
      name: sheetName,
      feedback: data,
    };

    axios
    .post(`${API_BASE_URL}/feedbackHumanly`, feedbackData)
    .then((response) => {
      if (response.status === 200) {
        message.success({ content: 'Submitted', key: 'loading',className: 'custom-message' }); // Changed from loading to success

        // Successful submission, reset the feedbackData and hide the feedback dialog
      } else {
        console.error('Error submitting feedback:', response.statusText);
      }
    })
    .catch((error) => {
      console.error('Error submitting feedback:', error);
    });

  }
function getDeviceType() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobile = /android|webos|iphone|ipad|ipod|blackberry|windows phone/.test(userAgent);
  return isMobile ? 'Mobile' : 'Web';
}

  const handleUpload = async () => {
    try {
      localStorage.setItem('dialogOpened', 'false');

      console.log('Uploading files...');
  
      // Counter to track completed API calls
      let completedUploads = 0;
  
      // Iterate through selectedFiles one by one
      for (let index = 0; index < selectedFiles.length; index++) {
        const file = selectedFiles[index];
        const formData = new FormData();
        formData.append('chatType', 'gpt_doc_bot');
        formData.append('deviceId', props.deviceId);
        formData.append('file', file);
        message.loading({ content: 'Uploading ' + file.name, key: 'loading',duration: 25,className: 'custom-message' });
  
        // Make the API call for the current file
        await axios.post(`${API_BASE_URL}/bot_doc`, formData, {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
  
            setUploadProgress((prevProgress) => {
              const newProgress = [...prevProgress];
              newProgress[index] = percentCompleted;
              return newProgress;
            });
  
            console.log(`Upload progress for File ${index + 1}: ${percentCompleted}%`);
          },
        });
  
        const filenamesInLocalStorage = JSON.parse(localStorage.getItem('filenames')) || {};
        filenamesInLocalStorage[props.deviceId] = filenamesInLocalStorage[props.deviceId] || [];
        filenamesInLocalStorage[props.deviceId].push(file.name);
        localStorage.setItem('filenames', JSON.stringify(filenamesInLocalStorage));
  
        // Increment the counter for each completed API call
        completedUploads++;
  
        // Check if all API calls are completed
        if (completedUploads === selectedFiles.length) {
          console.log('All files uploaded successfully!');
          message.success({ content: 'All Files Uploaded successfully', key: 'loading', duration: 2,className: 'custom-message' });
              const data = [
                  new Date().toLocaleString(),
                  props.deviceId,
                  file.name,
                  getDeviceType()
                ];
          handlePushDataToSheets("UploadTracker", data);
          setUploadSuccess(true);
          // Call toggleUploadContainer only after all files are uploaded
          // props.toggleUploadContainer();
        }
      }
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };
  

  return (
    <div className="upload-box">
      <div className="upload-text">Upload Files</div>
      <div className="choose-files">
        <div className="file-info">
          {'Choose files to upload'}
        </div>
        <input
  type="file"
  accept=".pdf,.doc,.docx,.txt"
  id="fileInput" // Add an ID to the input element
  style={{ display: 'none' }} // Hide the input element
  onChange={handleFileSelect}
  multiple
/>
<label htmlFor="fileInput" className="browse-button">
  Browse Files
</label>

      </div>
      <div
        className="drag-and-drop"
        onDrop={handleFileDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <div className="dotted-box">
          <div className="drag-icon">Drag and Drop Files</div>
        </div>
      </div>
      <div className="upload-progress">
        {selectedFiles.map((file, index) => (
          <div key={index}>
             {file.name}
            <progress value={uploadProgress[index] || 0} max="100"></progress>
          </div>
        ))}
      </div>
      <div>
        <button className={`start-chat-button ${uploadSuccess ? '' : 'disabled'}`} onClick={handleToggleContainer} disabled={!uploadSuccess}>Analyse and start chat</button>
      </div>
    </div>


  );

}

export default FileUpload;
