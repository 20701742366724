import React, { Component, useEffect, useRef, useState  } from 'react';
import { message } from 'antd';
import { API_BASE_URL } from './constants';
import './HomePage.css';
import FileUpload from './FileUpload';
import axios from 'axios';
import logo from './logo.png';
import UnlockDialogBox from './UnlockDialogBox';
import FeedbackDialogBox from './FeedbackDialogBox';
import {
  FaCopy,
  FaPlus,
  FaSync,
  FaThumbsUp,
  FaThumbsDown,
  FaPaperPlane,
  FaSave,
  FaEraser,
} from 'react-icons/fa';

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.bottomRef = React.createRef(); // Create a ref
    this.textAreaRef = React.createRef();
    this.state = {
      showAds: false,
      questionsAsked: 0,
      selectedPaymentOption: '',
      showFeedbackDialog: false,
      showSecondFeedbackDialog: false,
      feedbackData: {
        rating: 0,
        feedbackText: '',
      },
      showUploadContainer: true,
      inputText: '',
      answerText: '',
      previousInputText: '',
      previousAnswerText: '',
      deviceId: this.generateDeviceId(),
      characterCount: 0,
      isSubmitting: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if answerText has been updated
    if (prevState.answerText !== this.state.answerText) {
      this.scrollToBottomOfTextArea();
    }
  }

  scrollToBottomOfTextArea = () => {
    const textArea = this.textAreaRef.current;
    if (textArea) {
      textArea.scrollTop = textArea.scrollHeight;
    }
  };

  componentDidMount() {
    this.scrollToBottom(); // Call this method to scroll when component mounts
    const dialogOpenedBefore = localStorage.getItem('dialogOpened');

    if (dialogOpenedBefore) {
     // this.setState({ showAds: true });
    } else {
      localStorage.setItem('dialogOpened', 'true');
    }
  }
  scrollToBottom = () => {
    this.bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  generateDeviceId = () => {
    const storedDeviceId = localStorage.getItem('deviceId');
    if (storedDeviceId) {
      return storedDeviceId;
    } else {
      const newDeviceId = Math.random().toString(36).substr(2, 10);
      localStorage.setItem('deviceId', newDeviceId);
      return newDeviceId;
    }
  };

  handleSaveClick = () => {
    const { answerText } = this.state;
    const blob = new Blob([answerText], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'analyzed_answer.txt';
    a.click();
    URL.revokeObjectURL(url);
  };

  handleCopyClick = () => {
    const { answerText } = this.state;
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = answerText;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    tempTextArea.setSelectionRange(0, 99999);
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    alert('Text copied to clipboard');
  };

  handleInputChange = (event) => {
    const inputText = event.target.value;
    this.setState({
      inputText,
      characterCount: inputText.length,
    });
  };

  handleSubmitClick = () => {
    const { inputText } = this.state;
    console.log("inputText");
        console.log(inputText);
        if (!inputText.trim()) {
          message.error('Please enter some text');
        return;
        }

    this.setState({ isSubmitting: true });
    this.askQuestions(inputText);
  };

  handleRegenerateClick = () => {
    const { previousInputText } = this.state;
    console.log("previousInputText");
        if (!previousInputText.trim()) {
          message.error('Please enter some text');
        return;
        }
    this.setState({ isSubmitting: true });
    this.askQuestions(previousInputText);
  };

  handleUploadClick = () => {
    this.setState((prevState) => ({
      showUploadContainer: !prevState.showUploadContainer,
    }));
    
  };

  toggleUploadContainer = () => {
    this.setState((prevState) => ({
      showUploadContainer: !prevState.showUploadContainer,
    }),
    () => {
      this.scrollToBottom(); // Scroll to bottom after state update
    }
  );
};

  handleClearClick = () => {
    this.setState({
      inputText: '',
      answerText: '',
    });
  };

  handlePaymentOptionSelect = (option) => {
    this.setState({
      selectedPaymentOption: option,
      showFeedbackDialog: false,
      showSecondFeedbackDialog: true,
      //questionsAsked: 0,
      
    });
  };

  handleCloseFeedbackDialog = () => {
    this.setState({
      showFeedbackDialog: false,
      showSecondFeedbackDialog: false,
      //questionsAsked: 0,
      //showAds: true
    });
  };

  handleUnlockFeedbackStep = () => {
    this.setState({
      showFeedbackDialog: true,
    });
  };


  handleThumbsUpDownClick = (reportVal) => {
    const { previousInputText, previousAnswerText } = this.state;
    const sheetName = 'Thumbs up-down Report';
    const data = [
      new Date().toLocaleString(),
      this.state.deviceId,
      previousInputText,
      previousAnswerText,
      reportVal,
    ];
    message.loading({ content: 'Submiting Report Feedback', key: 'loading',className: 'custom-message' });

    this.handlePushDataToSheets(sheetName, data);

  };

  handlePushDataToSheets(sheetName, data) {
    const feedbackData = {
      name: sheetName,
      feedback: data,
    };

    axios
    .post(`${API_BASE_URL}/feedbackHumanly`, feedbackData)
    .then((response) => {
      if (response.status === 200) {
        message.success({ content: 'Submitted', key: 'loading',className: 'custom-message' }); // Changed from loading to success

        // Successful submission, reset the feedbackData and hide the feedback dialog
      } else {
        console.error('Error submitting feedback:', response.statusText);
      }
    })
    .catch((error) => {
      console.error('Error submitting feedback:', error);
    });

  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSubmitClick();
    }
  };

  handleSubmitFeedback = (feedbackVal) => {
    message.loading({ content: 'Submiting Report Feedback', key: 'loading',className: 'custom-message' });

    const feedbackText = feedbackVal.feedbackText;
    const rating = feedbackVal.rating1;
    const rating2 = feedbackVal.rating2;
    const selectedPaymentOption = this.state.selectedPaymentOption;
    const feedbackData = {
      feedback: [
        new Date().toLocaleString(),
        this.state.deviceId,
        feedbackText,
        rating,
        rating2,
        selectedPaymentOption,
      ],
    };

    axios
      .post(`${API_BASE_URL}/feedbackHumanly`, feedbackData)
      .then((response) => {
        if (response.status === 200) {
          message.success({ content: 'Feedback Submitted', key: 'loading',className: 'custom-message' }); // Changed from loading to success

          // Successful submission, reset the feedbackData and hide the feedback dialog
        } else {
          console.error('Error submitting feedback:', response.statusText);
        }
      })
      .catch((error) => {
        console.error('Error submitting feedback:', error);
      });

    this.setState({
      feedbackData: {
        rating: 0,
        feedbackText: '',
        //showAds: true
      },
      showFeedbackDialog: false,
      showSecondFeedbackDialog: false,
    });
  };

  handleStartOverClick = async () => {
    localStorage.removeItem('deviceId');
    localStorage.removeItem('filenames');
    this.setState({
      showUploadContainer: true,
      inputText: '',
      answerText: '',
      previousInputText: '',
      characterCount: 0,
    });

    try {
      console.log('Clearing session...');
      const formData = new FormData();
      formData.append('deviceId', this.state.deviceId);

      const response = await axios.post(`${API_BASE_URL}/clear_session`, formData);

      if (response.status === 200) {
        console.log('Session cleared successfully.');
      } else {
        console.error('Error clearing session:', response.statusText);
      }
    } catch (error) {
      console.error('Error clearing session:', error);
    }
  };

  askQuestions = async (msg) => {
    try {
      const { questionsAsked } = this.state;
      this.setState({
        questionsAsked: questionsAsked + 1,
      });

      if (questionsAsked == 3) {
        this.setState({
          showFeedbackDialog: true,
        });
      }

      this.setState({
        previousInputText: msg,
      });
      message.loading({ content: 'Please wait',className: 'custom-message', key: 'loading' , duration: 25    });

      const formData = new FormData();
      formData.append('chatType', 'gpt_doc_bot');
      formData.append('deviceId', this.state.deviceId);
      formData.append('message', msg);

      const response = await axios.post(`${API_BASE_URL}/bot_doc`, formData);

      if (response.status === 200) {
        message.success({ content: 'Humanly Responded', key: 'loading',className: 'custom-message' }); // Changed from loading to success

        const newAnswer = `${
          this.state.answerText ? this.state.answerText + '\n\n\n\n' : ''
        }Your Question: ${msg}\n\nHumanly Response: ${response.data.message}`;

        this.setState({
          answerText: newAnswer,
          inputText: '',
          isSubmitting: false,
          previousAnswerText: response.data.message,
        });
      }
    } catch (error) {
      this.setState({
        isSubmitting: false,
      });

      console.error('Error asking questions:', error);
    }
  };

  handleLogoClick = () => {
    window.location.href = '/'; // Replace '/' with the path of your landing page
  };


  render() {
    const {
      isSubmitting,
      questionsAsked,
      selectedPaymentOption,
      showFeedbackDialog,
      showSecondFeedbackDialog,
      feedbackData,
      inputText,
    } = this.state;

    const filenamesInLocalStorage =
      JSON.parse(localStorage.getItem('filenames')) || {};
    const filenames = filenamesInLocalStorage[this.state.deviceId] || [];
    const isFilenamesEmpty = filenames.length === 0;

  // Set the placeholder text based on whether files are uploaded
  const inputPlaceholder = isFilenamesEmpty
    ? "Great go ahead and upload your files. I can read PDFs, ppt, doc, dox. It'll be great if you can upload files upto 5MB only. Use the green 'Upload' button."
    :"Now that you have the files uploaded. Go ahead and ask your question." ;

    return (

      <div>
        <header className="header">
          <div className="logo" onClick={this.handleLogoClick} style={{ cursor: 'pointer' }}>
            <img src={logo} alt="Logo" />
          </div>
          <p className="tagline">as humans do</p>
          <hr className="divider" />
        </header>
        <div className="container" ref={this.bottomRef}>
        {this.state.showUploadContainer && (
          <div className="left-pane">
           {isFilenamesEmpty ? (
                            <div className="file-list">
                  <ul>
                        <li key='0'>No Files yet</li>

                    </ul>
            <button className="green-button" onClick={this.handleUploadClick}>
                   Upload
            </button>
              </div>
            ) : (
              <div className="file-list">
                  <ul>
                    {filenames.map((filename, index) => (
                        <li key={index}>{filename}</li>
                        ))}
                    </ul>
            <button className="green-button" onClick={this.handleUploadClick}>
                   Upload
            </button>
              </div>
            )}

          </div>
          )}
          <div className="right-pane">
            <main className="main-content">
            <div className="feedback-container">
            <a
          href="https://forms.gle/ooTirw9sygLedB849" // Replace with your feedback website URL
          target="_blank" // Open the link in a new tab
          rel="noopener noreferrer" // Recommended for security
          className="feedback-link"
        >
          Feedback
        </a>
      </div>
              <p className="text1">
                AI powered comprehension engine to chat with your documents.
              </p>
              
              <p className="text2">
                Try for FREE. Just upload your document and ask your questions!
              </p>
              {this.state.showUploadContainer ? null : (
                <FileUpload
                  toggleUploadContainer={this.toggleUploadContainer}
                  deviceId={this.state.deviceId}
                />
              )}
            </main>
            {this.state.showUploadContainer && (
          
              <div className="form-container">
                <div className="form-group">
                  <div className="text-field">
                    <textarea
                    ref={this.textAreaRef}
                      className="text-area-style"
                      placeholder={inputPlaceholder}
                      readOnly
                      value={this.state.answerText}
                    />
                    <div class="button-container">
                      <div class="row">
                        <button class="transparent-button" onClick={this.handleCopyClick}>
                          <FaCopy />
                        </button>
                        <button class="transparent-button" onClick={this.handleRegenerateClick}>
                          <FaSync />
                        </button>
                      </div>
                      <div class="row">
                        <button class="transparent-button" onClick={() => this.handleThumbsUpDownClick('positive')}>
                          <FaThumbsUp />
                        </button>
                        <button class="transparent-button" onClick={() => this.handleThumbsUpDownClick('negative')}>
                          <FaThumbsDown />
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="button-group">

                    {isFilenamesEmpty ? null : (
                      <div className="send-text-layout">
                        <input
                          type="text"
                          className="transparent-layout"
                          placeholder="Ask your Questions"
                          value={this.state.inputText}
                          onChange={this.handleInputChange}
                          onKeyPress={this.handleKeyPress}
                        />
                        <div>
                        {isFilenamesEmpty ? null : (
                          <button className="green-button" onClick={this.handleSubmitClick} disabled={!this.state.inputText.trim() || this.state.isSubmitting}>
                            <FaPaperPlane />
                          </button>
                        )}
                        {isFilenamesEmpty ? null : (
                      <div className="char-count-container">
    <span className="char-count">
      {this.state.characterCount}/5000
    </span>
  </div>
                    )}
                        </div>

                      </div>
                    )}

                    {isFilenamesEmpty ? null : (
                      <button className="green-button" onClick={this.handleSaveClick}>
                        <FaSave /> Save
                      </button>
                    )}
                    {isFilenamesEmpty ? null : (
                      <button className="green-button" onClick={this.handleClearClick}>
                        <FaEraser /> Clear
                      </button>
                    )}
                    {isFilenamesEmpty ? null : (
                        <button className="green-button" onClick={this.handleStartOverClick}>
                        New Files
                        </button>
                    )}
                  </div>

                </div>
              </div>
            )}
          </div>
          {questionsAsked >= 2 && showFeedbackDialog && (

            <UnlockDialogBox
              showAds={this.state.showAds} 
              onSelectPaymentOption={this.handlePaymentOptionSelect}
              onUnlockFeedbackStep={this.handleUnlockFeedbackStep}
              onClose={this.handleCloseFeedbackDialog}
            />
          )}
          {!this.state.showAds && showSecondFeedbackDialog && (
            <FeedbackDialogBox
              feedbackData={feedbackData}
              onSubmitFeedback={this.handleSubmitFeedback}
              onClose={this.handleCloseFeedbackDialog}
            />
          )}
        </div>
      </div>
    );
  }
}

export default HomePage;
