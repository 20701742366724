// In App.js or any other parent component

import React, { useState, Component } from 'react';
import HomePage from './HomePage'; // Import the HomePage component
import DialogBox from './DialogBox';
import UploadBox from './UploadBox';
import UnlockDialogBox from './UnlockDialogBox';
import LandingPage from './LandingPage'
import logo from './logo.png';
import humanlyIcon from './humanly_icon.png';

function App() {

  const [showLanding, setShowLanding] = useState(true);
  const [showHome, setShowHome] = useState(false);

  const toggleDivs = () => {
    setShowLanding(!showLanding);
    setShowHome(!showHome);
  };

  const handleLogoClick = () => {
    // Open the home page when the logo is clicked
    setShowLanding(false);
    setShowHome(true);
  };

const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const [isUnlockDialogOpen, setIsUnlockDialogOpen] = useState(false);

  const openUnlockDialog = () => {
    setIsUnlockDialogOpen(true);
  };

  const closeUnlockDialog = () => {
    setIsUnlockDialogOpen(false);
  };

  const [isUploadBoxOpen, setIsUploadBoxOpen] = useState(false);
const handleOpenUploadBox = () => {
    setIsUploadBoxOpen(true);
  };


  return (
      <div className="App">
             <div>
      {showLanding && (
        <div id="div1">
          <header className="header">
      <div className="logo" onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        <img src={logo} alt="Logo" />
      </div>

      <p className="tagline">as humans do</p>
      <hr className="divider" />

      <div className="content">
        <h1 className="middle-heading">AI powered comprehension engine to chat with your documents.</h1>

        <div className="content-bottom">
          <div className="left-content">
            <img src={humanlyIcon} alt="Humanly Icon" />
          </div>
          <div className="right-content">
            <h1 className="middle-heading-bold" style={{fontSize: '30px' }}>Need to read PDFs? Don't.</h1>
            <p className="middle-heading" style={{fontSize: '20px' }}>Effortlessly <span style={{ fontWeight: 'bold',fontSize: '20px' }}>upload your PDFs.</span><br />Simply <span style={{ fontWeight: 'bold' }}>ask any question</span>, <span style={{ fontWeight: 'bold' }}>Ask for a summary, translation and more</span></p>
      
            <p className="middle-heading" style={{fontSize: '20px' }}>Transform today the way you interact with …<br /><span style={{ fontWeight: 'bold' }}>legal documents, financial reports, white papers and more!</span></p>

           </div>
        </div>

        <div className="button-container"> {/* Container for centering the button */}
            <button className="get-started-button" onClick={toggleDivs}>Get started for free</button>
        </div>
      </div>
    </header>
        </div>
      )}

      {showHome && (
        <div id="div2">
          <HomePage/>
        </div>
      )}
    </div>
          </div>

    );
}

export default App;


//class App extends Component {
//  render() {
//    return (
//      <div className="App">
//        <HomePage /> {/* Use the HomePage component */}
//        {/* Other components and content */}
//      </div>
//    );
//  }
//}
//
//export default App;
