// UnlockDialogBox.js

import React from 'react';
import './UnlockDialogBox.css';
import AdSense from 'react-adsense';


function UnlockDialogBox(props) {
  const handleOptionSelect = (option) => {
    localStorage.setItem('dialogOpened', 'true');
    props.onSelectPaymentOption(option);

  };

  const handleClose = () => {
    // Call the onClose prop when the close button is clicked
    props.onClose();
  };

  return (
    console.log(props.showAds),
    <div className="unlock-dialog-box">
{props.showAds ? (
  
          <div className='ads-container' >

<AdSense.Google
            client='ca-pub-4312215078968091'
            slot='2359279846'
            style={{ display: 'block' }}
            format='auto'
            responsive='true'
          />
            <button onClick={handleClose}>Close</button>
          </div>
        ) : (
      <div className="unlock-dialog-content">

        <h2 className="dialog-heading">Unlock Unlimited Humanly.ai Use</h2>
        <p>To continue using the free service, please indicate what your preferred future subscription option:</p>
        <div className="button-container">
          <button className="green-button" onClick={() => handleOptionSelect('Day pass $5/day')}>
          Day pass $5/day
          </button>
          <button className="green-button" onClick={() => handleOptionSelect('Monthly subscription $15')}>
          Monthly subscription $15
          </button>
          <button className="green-button" onClick={() => handleOptionSelect('Annual subscription $40')}>
          Annual subscription $40
          </button>
          <button className="green-button" onClick={() => handleOptionSelect('Free with Ads')}>
          Free with Ads
          </button><br />


        </div>
      </div>
      )}
      <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>

    </div>
  );
}

export default UnlockDialogBox;
