import React, { useState } from 'react';
import { Slider, Rate, message } from 'antd';
import './FeedbackDialogBox.css';

const marks = {
  0: 'Least likely',
  20: '1',
  40: '2',
  60: '3',
  80: '4',
  100: 'Most likely',
};

function FeedbackDialogBox(props) {
  const [rating1, setRating1] = useState(0); // New rating state
  const [rating2, setRating2] = useState(0); // Rating for "How would you rate humanly.ai based on your use today?"
  const [feedbackText, setFeedbackText] = useState('');

  const handleTrustpilotClick = () => {
    window.open("https://uk.trustpilot.com/review/humanly.ai", "_blank");
    props.onClose(); // Assuming you have a method to close the dialog in props
  };

  const handleRatingChange1 = (value) => {
    console.log("Rating1", value/20);
    setRating1(value/20);
  };

  const handleRatingChange2 = (value) => {
    console.log("Rating2", value);
    setRating2(value);
  };

  const handleFeedbackTextChange = (event) => {
    setFeedbackText(event.target.value);
  };

  const handleSubmitFeedback = () => {
    if (rating1 > 0 && rating2 > 0 && feedbackText.trim() !== '') {
      // Save the feedback data in state
      const feedbackData = {
        rating1: rating1, // New rating
        rating2: rating2, // Rating for "How would you rate humanly.ai based on your use today?"
        feedbackText: feedbackText,
      };

      // Pass the feedback data to the parent component
      props.onSubmitFeedback(feedbackData);
    } else {
      // Display an alert or handle the case where not all fields are filled
      message.error({ content: 'Please fill in all fields before submitting.', key: 'loading', duration: 2,className: 'custom-message' });

    }
 
  };

  return (
    <div className="feedback-dialog-box">
      <div className="feedback-dialog-content">
        <h2>Please provide your feedback</h2>
        <p>How likely are you to refer humanly.ai to others?</p>
                <Slider marks={marks} step={null} defaultValue={60} onAfterChange={handleRatingChange1} trackStyle={{ backgroundColor: '#689f38' }} 
          />
<br></br>

        <p>How would you rate humanly.ai based on your use today?</p>
        <Rate onChange={handleRatingChange2} />
        <p>Tell us why you used humanly.ai today:</p>
        <textarea
          rows="4"
          value={feedbackText}
          onChange={handleFeedbackTextChange}
        ></textarea>

        <div className="button-container">
          <button onClick={handleSubmitFeedback}>Submit</button>

          <p style={{ textAlign: 'center', margin: '20px 0' }}>OR</p> 

          <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async></script>
<div class="trustpilot-widget" onClick={handleTrustpilotClick} data-locale="en-GB" data-template-id="56278e9abfbbba0bdcd568bc" data-businessunit-id="652663fadf9c54658cbce1e9" data-style-height="52px" data-style-width="100%">
  <a href="https://uk.trustpilot.com/review/humanly.ai" target="_blank" rel="noopener">Review us on Trustpilot</a>
</div>



        </div>
      </div>
    </div>
  );
}

export default FeedbackDialogBox;
